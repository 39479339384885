import './CardsBox.scss';
import './CardsBoxMediaQuery.scss';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useDarkMode } from '../../NavbarComponent/DarkModeContext';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import { formatNumber } from '../../../Utils/utils';
import { getUser } from '../../../auth';
import { useImporters } from '../../ImportersProvider';

function CardsComex() {
  const { isDarkMode } = useDarkMode();
  const importersList = useImporters();
  const user = React.useMemo(() => getUser(), []);
  const [barChartData, setBarChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        if (importersList.default) {
          const response = await fetch(
            'https://api.useblip.com/importer-summary?company=' +
              user.company +
              '&importer=' +
              importersList.default
          );
          if (!response.ok) {
            throw new Error('Error al obtener los datos');
          }
          const data = await response.json();

          setBarChartData(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [user.company, importersList.default]);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Grid className="CardsContainerCard">
      <div className="CardsLineContainer">
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              {localStorage.getItem('importer').charAt(0).toUpperCase() +
                localStorage.getItem('importer').slice(1).toLowerCase()}{' '}
              - Última Importación
            </h5>
          </div>
          <div className="CardsMainText">
            <p className="CardsMainTextContent">
              ${formatNumber(barChartData.last_month)} USD
            </p>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              {localStorage.getItem('importer').charAt(0).toUpperCase() +
                localStorage.getItem('importer').slice(1).toLowerCase()}{' '}
              - FOB {barChartData.last_year_data}
            </h5>
          </div>
          <div className="CardsMainText">
            <Tooltip title="Year to date">
              <p className="CardsMainTextContent">
                ${formatNumber(barChartData.ytd)} USD
              </p>
            </Tooltip>
            <div className="CardsSubTextContainer">
              <p className="CardsMainSubTextRight">-</p>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
        <div
          className={`CardsComponent ${isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'}`}
        >
          <div
            className={`CardsHeader ${isDarkMode ? 'CardsBorderBottomDark' : 'CardsBorderBottomLight'}`}
          >
            <h5 className="CardsHeaderTitle">
              {localStorage.getItem('importer').charAt(0).toUpperCase() +
                localStorage.getItem('importer').slice(1).toLowerCase()}{' '}
              - FOB {parseInt(barChartData.last_year_data) - 1}
            </h5>
          </div>
          <div className="CardsMainText">
            <Tooltip title="Same period last year">
              <p className="CardsMainTextContent flex flex-col">
                SPLY: ${formatNumber(barChartData.sply)} USD
              </p>
            </Tooltip>
            <div className="CardsSubTextContainer">
              <Tooltip title="Full year">
                <p className="CardsMainSubTextRight">
                  FY: ${formatNumber(barChartData.lyt)} USD
                </p>
              </Tooltip>
              <p className="CardsMainSubTextLeft">-</p>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default CardsComex;
