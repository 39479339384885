import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import './TableComponent.scss';
import './TableComponentMediaQuery.scss';
import { useDarkMode } from '../NavbarComponent/DarkModeContext';
import { CircularProgress } from '@mui/material';
import { format, parse } from 'date-fns';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getUser } from '../../auth';
import { isNumber } from 'lodash';
import { formatNumber } from '../../Utils/utils';

const TextFilter = ({ column: { filterValue, setFilter, Header } }) => {
  const placeholderText = `${Header}`;

  const { isDarkMode } = useDarkMode();

  const value = isNumber(filterValue) ? formatNumber(filterValue) : filterValue;

  return (
    <input
      className={`searchFilterDespachos ${
        isDarkMode ? 'TableSearchFilterDark' : 'TableSearchFilterLight'
      }`}
      value={value || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={placeholderText}
      onClick={(e) => e.stopPropagation()} // Prevent sorting when clicking on the filter input
    />
  );
};

const handleRowDoubleClick = (rowData) => {
  const rowValues = Object.entries(rowData)
    .map(([key, value]) => {
      if (key === 'Precio') {
        return `$${value} ARS`;
      }
      return value;
    })
    .join(' | ');

  const tempInput = document.createElement('input');
  tempInput.type = 'text';
  tempInput.value = rowValues;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.setSelectionRange(0, 99999); // For mobile devices
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};

const TableComponent = () => {
  const { isDarkMode } = useDarkMode();
  const user = React.useMemo(() => getUser(), []);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear() - 1;
  const years = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());

  React.useEffect(() => {
    // Función para obtener los datos de la API
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.useblip.com/importer-table?company=' + user.company
        );
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        const data = await response.json();
        setTableData(data.orders);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [user.company]);

  const tableDataByYear = useMemo(() => {
    if (tableData.length === 0) {
      return [];
    }
    return tableData
      .filter((data) => data.date.includes(selectedYear.toString()))
      .map((dataItem) => ({
        ...dataItem,
        date: format(new Date(dataItem.date), 'dd-MM-yyyy'),
      }));
  }, [tableData, selectedYear]);

  // State to manage the visibility of text filters
  const [showTextFilters, setShowTextFilters] = useState(false);

  // Function to toggle the visibility of text filters
  const toggleTextFilters = () => {
    setShowTextFilters((prevValue) => !prevValue);
  };

  const formatPrecio = (value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      console.error('Invalid numeric value:', value);
      return 'N/A';
    }

    const numberWithLocalFormat = numericValue.toLocaleString('de-DE', {
      maximumFractionDigits: 4,
    });

    return `$${numberWithLocalFormat}`;
  };

  const handleCellClick = (cellValue, key) => {
    const tempInput = document.createElement('input');
    tempInput.type = 'text';

    if (key === 'Precio') {
      tempInput.value = `$ ${cellValue} ARS`;
    } else {
      tempInput.value = cellValue;
    }

    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  const columns = useMemo(
    () => [
      { Header: 'NCM', accessor: 'ncm', Filter: TextFilter },
      {
        Header: 'Fecha',
        accessor: 'date',
        Filter: TextFilter,
        width: 110,
        sortType: (rowA, rowB, columnId) => {
          const dateA = parse(rowA.values[columnId], 'dd-MM-yyyy', new Date());
          const dateB = parse(rowB.values[columnId], 'dd-MM-yyyy', new Date());
          return dateA > dateB ? 1 : -1;
        },
      },
      {
        Header: 'Importador',
        accessor: 'importer',
        Filter: TextFilter,
        width: 200,
      },
      { Header: 'Transporte', accessor: 'shipping', Filter: TextFilter },
      { Header: 'Aduana', accessor: 'aduana', Filter: TextFilter },
      { Header: 'Marca', accessor: 'brand', Filter: TextFilter },
      {
        Header: 'Producto',
        accessor: 'product',
        Filter: TextFilter,
        width: 200,
      },
      { Header: 'Formato', accessor: 'format', Filter: TextFilter },
      {
        Header: 'Un. Medida',
        accessor: 'unit',
        Filter: TextFilter,
        width: 100,
      },
      {
        Header: 'Origen',
        accessor: 'country_origin',
        Filter: TextFilter,
        width: 100,
      },
      {
        Header: 'Procedencia',
        accessor: 'country_provenance',
        Filter: TextFilter,
        width: 100,
      },
      {
        Header: 'Incoterm',
        accessor: 'incoterm',
        Filter: TextFilter,
        width: 100,
      },
      {
        Header: 'Cant.',
        accessor: 'quantity_product',
        Filter: TextFilter,
        width: 50,
      },
      {
        Header: 'FOB Unitario',
        accessor: 'unit_usd',
        Filter: TextFilter,
        sortType: 'alphanumeric',
        Cell: ({ value, column }) => (
          <span
            onClick={() => handleCellClick(value, column.id)}
            style={{ cursor: 'pointer' }}
          >
            {formatPrecio(value)}
          </span>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableDataByYear }, useFilters, useSortBy);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <div
      className={`BigTableWrapper ${
        isDarkMode ? 'CardBackgroundColorDark' : 'CardBackgroundColorLight'
      }`}
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="80px"
          borderBottom={'1px solid rgba(255, 255, 255, 0.5019607843)'}
          gap={'8px'}
          paddingRight={'16px'}
        >
          <h5 className="BigTableHeaderTitle">Importaciones Detalladas</h5>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 61 }}
            size="small"
          >
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedYear}
              placeholder="Año"
              onChange={(val) => setSelectedYear(val.target.value)}
              sx={{
                color: 'white',
                '$ .MuiInputBase-input': {
                  borderBottomColor: 'white',
                },
              }}
            >
              {years.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FilterAltOutlinedIcon
            onClick={() => toggleTextFilters()}
            sx={{
              marginRight: '8px',
              cursor: 'pointer',
            }}
          />
        </Stack>
      </Box>
      <div className="table-container">
        <table className="BigTableContainer" {...getTableProps()}>
          <thead
            className={`BigTableThead ${
              isDarkMode
                ? 'TableBackgroundColorTheadDark'
                : 'TableBackgroundColorTheadLight'
            }`}
          >
            {/* Render the header rows */}
            {headerGroups.map((headerGroup) => (
              <tr
                className={`BigTableTr ${
                  isDarkMode
                    ? 'TableMainTextColorsDark'
                    : 'TableMainTextColorsLight'
                }`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="BigTableTh"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ padding: '10px', minWidth: column.width }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* The rest of the header content remains the same */}
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ''}
                      </span>
                    </div>
                    {/* Render the filter UI for each column */}
                    {column.canFilter && showTextFilters && (
                      <div className="filterDiv">{column.render('Filter')}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="BigTableTbody" {...getTableBodyProps()}>
            {/* Render all rows */}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className="BigTableTbodyTr"
                  {...row.getRowProps()}
                  onDoubleClick={() => handleRowDoubleClick(row.original)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="BigTableTbodyTd"
                        {...cell.getCellProps()}
                        style={{ padding: '10px', cursor: 'pointer' }}
                        onClick={() => handleCellClick(cell.value)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
